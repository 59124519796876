// src/components/Challenges.js
import React from 'react';
import './Challenges.css';

const Challenges = () => {
  return (
    <div className="challenges">
      <h1>Challenges</h1>
      <p>Content for challenges will go here.</p>
    </div>
  );
};

export default Challenges;
